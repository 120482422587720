import React from 'react';


let Footer = (props)=>{


	return (

			<footer className="footer-section">
				<div className="footer-section--container">
					<div className="footer-section--container--logo">
						<img src="img/logo-main_logo-main.png" alt="logo"/>
					</div>
					<div className="footer-section--container--copyright">
					© {new Date().getFullYear()} БИТ. Все права защищены.
					</div>
					<div className="footer-section--container--social">
					
					</div>
				
				</div>

			</footer>


		)

}


export default Footer;


