import React from 'react';



export default function InfoSection(props){




	return (

		<section className="info__section" id="services">
			<div className="info__section--container">
				
					<div className="info__section--container--first__block">
						<div className="info__section--container--first__block--item">
							<img src="img/icon-ok-02.svg" className="info__section--container--first__block--item--logo" alt=""/>
							<span className="info__section--container--first__block--item--text">
								Наши специалисты высокой квалификации произведут интеграцию в кратчайшие сроки.
							</span>
						</div>
						<div className="info__section--container--first__block--item">
							<img src="img/icon-ok-03.svg" className="info__section--container--first__block--item--logo" alt=""/>
							<span className="info__section--container--first__block--item--text">
							Вы получите подробнейшие инструкции и советы от людей с суммарно десятками лет опыта, а также - целый набор рекомендаций по возможностям развития и улучшения вашей инфраструктуры
							</span>
						</div>
					</div>
					<div className="info__section--container--second__block">
						<div className="info__section--container--second__block--item">
							<img src="img/icon-ok-04.svg" className="info__section--container--second__block--item--logo" alt=""/>
							<span className="info__section--container--second__block--item--text">
							Мы проводим поставку и внедрение всего спектра техники и оборудования для автоматизации и информатизации современного предприятия.
							</span>
						</div>
						<div className="info__section--container--second__block--item">
							<img src="img/icon-ok-05.svg" className="info__section--container--second__block--item--logo" alt=""/>
							<span className="info__section--container--second__block--item--text">
							Безопасность - наше кредо. Мы производим, поставляем и внедряем лучшие решения на рынке по конкурентоспособным ценам.
							</span>
						</div>
					</div>
				
			</div>
		</section>
		
		)

}