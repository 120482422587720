import React, { useRef, useEffect, useContext } from 'react';

import Menu from "./Menu";

import {Context} from "./OurContext";

import {TweenMax, Power2} from "gsap/TweenMax";


function Logo(props){


	return (

		<div className={props.className}> <a href={props.logoLink}> <img src={props.logoSrc} width="180px" height="120px" alt="logo"/> </a> </div>

		)

}


export default function Header(props){


	let ourContext = useContext(Context);

	ourContext.color = 'blue';

	const textBlockRef = useRef(null);

	useEffect( ()=>{  TweenMax.from( textBlockRef.current, 1, {opacity: 0, scale:0.4, ease: Power2.easeOut} )    }, []   ) ;

	

	return (

		<header className="header">
			<div className="header--container">
				<Logo className="header--container--logo" logoLink={props.logoLink} logoSrc={props.logoSrc}/>
				<div className="header--container--text-block" ref={textBlockRef}>
					<h1 className="header--container--text-block--title">
					Безопасность информационных технологий
					</h1>
					<p className="header--container--text-block--description">
					Наш опыт на службе информационной безопасности вашего дела. Информационные продукты и услуги высшего качества.
					</p>
				</div>
				<Menu/>
			</div>
		</header> 

		);
}







