import React from 'react';



import HamburgerButton from './HamburgerMenu';
import "./Menu.css";





export default function Menu(){


	return (

			
			<nav className="mainNav">
			 <ul className="mainMenu">
				

				<li><div id='hamburger'> <HamburgerButton height='3em' 
			 			toggler={(toggle)=>{
			 					
			 						let elems = document.getElementsByClassName("menuItem");
			 						Array.prototype.forEach.call(elems, (elem)=>{elem.classList.toggle('displayMenu')} )
			 						
								
								
									}}/> </div></li>			 	
			
				<li className="menuItem"><a href="#services">Услуги</a></li>
				<li className="menuItem"><a href="#contacts">Контакты</a></li>
			  </ul>
			 </nav>
			)

}

